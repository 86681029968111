/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SiteSearchResourceCollectionResponse } from '../models/site-search-resource-collection-response';
import { SiteSearchRequest } from '../models/site-search-request';
@Injectable({
  providedIn: 'root',
})
class SitesService extends __BaseService {
  static readonly searchSitesPath = '/search-sites';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Endpoint to search for sites
   *
   * Endpoint to search for sites
   * @param params The `SitesService.SearchSitesParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `siteSearchRequest`: The site search request containing the parameters specified by the user
   *
   * @return OK
   */
  searchSitesResponse(params: SitesService.SearchSitesParams): __Observable<__StrictHttpResponse<SiteSearchResourceCollectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __headers = __headers.set('token', params.token.toString());
    __body = params.siteSearchRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/search-sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SiteSearchResourceCollectionResponse>;
      })
    );
  }
  /**
   * Endpoint to search for sites
   *
   * Endpoint to search for sites
   * @param params The `SitesService.SearchSitesParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `siteSearchRequest`: The site search request containing the parameters specified by the user
   *
   * @return OK
   */
  searchSites(params: SitesService.SearchSitesParams): __Observable<SiteSearchResourceCollectionResponse> {
    return this.searchSitesResponse(params).pipe(
      __map(_r => _r.body as SiteSearchResourceCollectionResponse)
    );
  }
}

module SitesService {

  /**
   * Parameters for searchSites
   */
  export interface SearchSitesParams {
    token: string;

    /**
     * The site search request containing the parameters specified by the user
     */
    siteSearchRequest: SiteSearchRequest;
  }
}

export { SitesService }
