/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TokenGenerationRequest } from '../models/token-generation-request';
import { TokenValidationResourceResponse } from '../models/token-validation-resource-response';

/**
 * Token generation and validation for external users
 */
@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  static readonly generateTokenPath = '/generate-token';
  static readonly validateTokenPath = '/validate-token';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Endpoint to generate a token for an external user access
   *
   * Endpoint to generate a token for an external user access
   * @param params The `TokenService.GenerateTokenParams` containing the following parameters:
   *
   * - `tokenGenerationRequest`: The access request containing the tip and the email of the external user
   *
   * - `locale`:
   */
  generateTokenResponse(params: TokenService.GenerateTokenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tokenGenerationRequest;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/generate-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Endpoint to generate a token for an external user access
   *
   * Endpoint to generate a token for an external user access
   * @param params The `TokenService.GenerateTokenParams` containing the following parameters:
   *
   * - `tokenGenerationRequest`: The access request containing the tip and the email of the external user
   *
   * - `locale`:
   */
  generateToken(params: TokenService.GenerateTokenParams): __Observable<null> {
    return this.generateTokenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Endpoint to validate the token for an external user access
   *
   * Endpoint to validate the token for an external user access
   * @param params The `TokenService.ValidateTokenParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  validateTokenResponse(params: TokenService.ValidateTokenParams): __Observable<__StrictHttpResponse<TokenValidationResourceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __headers = __headers.set('token', params.token.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/validate-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenValidationResourceResponse>;
      })
    );
  }
  /**
   * Endpoint to validate the token for an external user access
   *
   * Endpoint to validate the token for an external user access
   * @param params The `TokenService.ValidateTokenParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  validateToken(params: TokenService.ValidateTokenParams): __Observable<TokenValidationResourceResponse> {
    return this.validateTokenResponse(params).pipe(
      __map(_r => _r.body as TokenValidationResourceResponse)
    );
  }
}

module TokenService {

  /**
   * Parameters for generateToken
   */
  export interface GenerateTokenParams {

    /**
     * The access request containing the tip and the email of the external user
     */
    tokenGenerationRequest: TokenGenerationRequest;
    locale?: string;
  }

  /**
   * Parameters for validateToken
   */
  export interface ValidateTokenParams {
    token: string;
    locale?: string;
  }
}

export { TokenService }
