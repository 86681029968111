/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CameraSectionResourceCollectionResponse } from '../models/camera-section-resource-collection-response';
import { ReasonForRequestResourceCollectionResponse } from '../models/reason-for-request-resource-collection-response';
@Injectable({
  providedIn: 'root',
})
class ImageRequestFormService extends __BaseService {
  static readonly getCameraSectionsPath = '/camera-sections';
  static readonly getReasonsForRequestPath = '/reasons-for-request';
  static readonly requestImagesPath = '/request-images';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Endpoint to get all the camera sections
   *
   * Endpoint to get all the camera sections
   * @param params The `ImageRequestFormService.GetCameraSectionsParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  getCameraSectionsResponse(params: ImageRequestFormService.GetCameraSectionsParams): __Observable<__StrictHttpResponse<CameraSectionResourceCollectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __headers = __headers.set('token', params.token.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/camera-sections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CameraSectionResourceCollectionResponse>;
      })
    );
  }
  /**
   * Endpoint to get all the camera sections
   *
   * Endpoint to get all the camera sections
   * @param params The `ImageRequestFormService.GetCameraSectionsParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  getCameraSections(params: ImageRequestFormService.GetCameraSectionsParams): __Observable<CameraSectionResourceCollectionResponse> {
    return this.getCameraSectionsResponse(params).pipe(
      __map(_r => _r.body as CameraSectionResourceCollectionResponse)
    );
  }

  /**
   * Endpoint to get all the reasons for request
   *
   * Endpoint to get all the reasons for request
   * @param params The `ImageRequestFormService.GetReasonsForRequestParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  getReasonsForRequestResponse(params: ImageRequestFormService.GetReasonsForRequestParams): __Observable<__StrictHttpResponse<ReasonForRequestResourceCollectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __headers = __headers.set('token', params.token.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reasons-for-request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReasonForRequestResourceCollectionResponse>;
      })
    );
  }
  /**
   * Endpoint to get all the reasons for request
   *
   * Endpoint to get all the reasons for request
   * @param params The `ImageRequestFormService.GetReasonsForRequestParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `locale`:
   *
   * @return OK
   */
  getReasonsForRequest(params: ImageRequestFormService.GetReasonsForRequestParams): __Observable<ReasonForRequestResourceCollectionResponse> {
    return this.getReasonsForRequestResponse(params).pipe(
      __map(_r => _r.body as ReasonForRequestResourceCollectionResponse)
    );
  }

  /**
   * Endpoint to request images after filling out the form
   *
   * Endpoint to request images after filling out the form
   * @param params The `ImageRequestFormService.RequestImagesParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `startTime`:
   *
   * - `reasonForRequestId`:
   *
   * - `externalSiteId`:
   *
   * - `endTime`:
   *
   * - `description`:
   *
   * - `dateOfEvents`:
   *
   * - `cameraSectionIds`:
   *
   * - `attachedFile`:
   *
   * - `policeProcedures`:
   *
   * - `locale`:
   *
   * - `contactTelephone`:
   */
  requestImagesResponse(params: ImageRequestFormService.RequestImagesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.token != null) __headers = __headers.set('token', params.token.toString());
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    if (params.reasonForRequestId != null) __params = __params.set('reasonForRequestId', params.reasonForRequestId.toString());
    if (params.externalSiteId != null) __params = __params.set('externalSiteId', params.externalSiteId.toString());
    if (params.endTime != null) __params = __params.set('endTime', params.endTime.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.dateOfEvents != null) __params = __params.set('dateOfEvents', params.dateOfEvents.toString());
    (params.cameraSectionIds || []).forEach(val => {if (val != null) __params = __params.append('cameraSectionIds', val.toString())});
    if (params.attachedFile != null) { __formData.append('attachedFile', params.attachedFile as string | Blob);}
    if (params.policeProcedures != null) __params = __params.set('policeProcedures', params.policeProcedures.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.contactTelephone != null) __params = __params.set('contactTelephone', params.contactTelephone.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/request-images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Endpoint to request images after filling out the form
   *
   * Endpoint to request images after filling out the form
   * @param params The `ImageRequestFormService.RequestImagesParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `startTime`:
   *
   * - `reasonForRequestId`:
   *
   * - `externalSiteId`:
   *
   * - `endTime`:
   *
   * - `description`:
   *
   * - `dateOfEvents`:
   *
   * - `cameraSectionIds`:
   *
   * - `attachedFile`:
   *
   * - `policeProcedures`:
   *
   * - `locale`:
   *
   * - `contactTelephone`:
   */
  requestImages(params: ImageRequestFormService.RequestImagesParams): __Observable<null> {
    return this.requestImagesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ImageRequestFormService {

  /**
   * Parameters for getCameraSections
   */
  export interface GetCameraSectionsParams {
    token: string;
    locale?: string;
  }

  /**
   * Parameters for getReasonsForRequest
   */
  export interface GetReasonsForRequestParams {
    token: string;
    locale?: string;
  }

  /**
   * Parameters for requestImages
   */
  export interface RequestImagesParams {
    token: string;
    startTime: string;
    reasonForRequestId: string;
    externalSiteId: string;
    endTime: string;
    description: string;
    dateOfEvents: string;
    cameraSectionIds: Array<string>;
    attachedFile: Blob;
    policeProcedures?: string;
    locale?: string;
    contactTelephone?: string;
  }
}

export { ImageRequestFormService }
