import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

import { LoginService } from '@pages/login/services/login.service';
import { TokenValidationResourceResponse } from 'app/api/backend/models';

import { MPageErrorService } from '@mercadona/core-ui/page-error';
import { MTranslateService } from '@mercadona/core/translate';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private mPageErrorService: MPageErrorService,
    private mTranslateService: MTranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = route.queryParamMap.get('token');
    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    return this.loginService.validateToken(token).pipe(
      catchError((res: HttpErrorResponse) => {
        const title = this.mTranslateService.translate('global.errorPage.title');
        const subtitle = this.mTranslateService.translate('global.errorPage.subtitle');
        let message = '';
        if (res.status === 500) {
          message = this.mTranslateService.translate('global.errorPage.messageError500');
        } else if (res.error.error?.code) {
          message = this.mTranslateService.translate('validate.errorCodes.' + res.error.error.code);
        }
        this.mPageErrorService.navigateToError({
          title,
          subtitle,
          message
        });
        return throwError(() => false);
      }),
      tap((res) => {
        const resToken = res as TokenValidationResourceResponse;
        if (resToken.data) {
          this.loginService.email = resToken.data.email;
          this.loginService.tip = resToken.data.tip;
          this.loginService.countryCode = resToken.data.countryCode;
          this.loginService.token = token;
        }
      }),
      map((res) => (res as TokenValidationResourceResponse).data?.tip !== undefined)
    );
  }
}
