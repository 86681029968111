import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TokenValidationResourceResponse } from 'app/api/backend/models';
import { TokenService } from 'app/api/backend/services';

import { MTranslateService } from '@mercadona/core/translate';

/**
 * LoginService
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  tip?: string;
  email?: string;
  countryCode?: string;
  token!: string;

  /**
   * Class constructor
   *
   * @param {TokenService} tokenService Servicio para la generación y validación del token
   * @param {MTranslateService} mTranslateService Servicio de traducción
   */
  constructor(private tokenService: TokenService, private mTranslateService: MTranslateService) {}

  generateToken(tip: string, email: string): Observable<null | HttpErrorResponse> {
    const params: TokenService.GenerateTokenParams = {
      tokenGenerationRequest: {
        tip,
        email
      },
      locale: this.mTranslateService.getActiveLocale()
    };
    return this.tokenService.generateToken(params);
  }

  validateToken(token: string): Observable<TokenValidationResourceResponse | HttpErrorResponse> {
    const params: TokenService.ValidateTokenParams = {
      token,
      locale: this.mTranslateService.getActiveLocale()
    };
    return this.tokenService.validateToken(params);
  }
}
