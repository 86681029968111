import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MPageNotFoundComponent } from '@mercadona/core-ui/page-not-found';

import { TokenGuard } from './guards/token.guard';

// IMPORTANT: Do not use the "/error" route as it will be overwritten by the Core module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/014302-page-error
// IMPORTANT: Do not use the "/monitoring_nginx" route as it will be overwritten by Nginx Configuration
// IMPORTANT: Do nout user the "/callback" as it will be overwritten by the Token module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/043ae9-rutas-no-permitidas

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login-page.module').then((m) => m.LoginPageModule)
  },
  {
    path: 'request-images',
    loadChildren: () =>
      import('./pages/request-images/request-images-page.module').then((m) => m.RequestImagesPageModule),
    canActivate: [TokenGuard]
  },
  {
    path: '**',
    component: MPageNotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
